import React, { ReactElement, useState } from "react"
import TabTitle from "./TabTitle"
import styled from "styled-components"
import { fonts, colors, layout, bp } from "../Styles"
import { useLocation } from "@reach/router"

type Props = {
  title?: string
  children: ReactElement[]
}

const Tabs: React.FC<Props> = ({ children, title }) => {
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState(0)
  const path = location.hash.split("#")[1]

  return (
    <>
      <BackgroundDark>
        <TabsContainer>
          <TabsHeadLine>{title}</TabsHeadLine>
          <Nav>
            {children.map((item, index) => (
              <TabTitle
                key={index}
                title={item.props.title}
                urlTab={path === item.props.title ? index : undefined}
                {...{ selectedTab, setSelectedTab, index }}
              />
            ))}
          </Nav>
        </TabsContainer>
      </BackgroundDark>
      {children[selectedTab]}
    </>
  )
}
export const BackgroundDark = styled.div`
  background: ${colors.backgroundDarker};
`
export const TabsContainer = styled.div`
  width: 103rem;
  max-width: calc(100% - 12rem);
  margin: 0 auto;
  padding-bottom: 5em;
  & div {
    padding-top: 4rem;
    color: #fff;
    font-size: 1.6em;
    max-width: 49%;
  }

  width: ${layout.width};
  max-width: ${layout.maxWidth};
  margin: 0 auto;
  ${bp("mobile")`
    max-width: ${layout.maxWidthMobile};
    & div {
      max-width: 70%;
      padding: 4rem 0;
    }
  `}
`

const TabsHeadLine = styled.h2`
  font-size: 4.8em;
  color: ${colors.primary};
  max-width: 45%;
  padding-bottom: 1em;
  ${bp("mobile")`
  max-width: 100%;
  `}
`

const Nav = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-bottom: 2px solid ${colors.primary};
  border-style: dashed;
`
export default Tabs
