import React, { useRef } from "react"
import Vimeo from "@u-wave/react-vimeo"
import styled from "styled-components"
import { fonts, colors, bp } from "../components/Styles"
import {
  HeadlineContainer,
  ImageContainer,
  TextBlock,
  Link,
  StickyContentContainer,
  TextSegment,
  StickyContent,
  Spacing,
  MinSpacing,
  SmSpacing,
  SpSpacing,
  BackgroundContainer,
} from "../components/layout-components"
import { useStaticQuery, graphql } from "gatsby"
import { asBg } from "../util/image"
import Tabs, { TabsContainer, BackgroundDark } from "./tabs/Tabs"
import Tab from "./tabs/Tab"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import InfoMenu from "./InfoMenu"

import animationAbrechnung from "../lottie/cases/P_01_Abrechnung.json"
import animationMehrfach from "../lottie/cases/P_02_Mehrfachnutzung.json"
import animationExterne from "../lottie/cases/P_03_ExterneVermietung.json"
import animationZeitersparnis from "../lottie/cases/P_04_Zeitersparniss.json"
import animationLade from "../lottie/cases/P_07_Ladedock.json"

const Container = styled.div`
  width: 100%;
  background: ${colors.white};
  ${bp("hdpi")`
    .last-block {
      padding-top:6em;
      padding-bottom:6em;
    }
  `}
  ${bp("mobile")`
    iframe {
      height: 61vw;
    }
  `}
  iframe {
    max-width: 100%;
  }
`

const Headline = styled.h2<{
  colorTheme?: string
}>`
  color: ${colors.primary};
  text-transform: uppercase;
  font-size: 9em;
  padding-top: 1em;
  ${bp("mobile")`
  font-size: 5em;
  `}
  span {
    color: ${({ colorTheme }) =>
      colorTheme === "white" ? colors.white : colors.backgroundDarker};
  }
`
const Text = styled(TabsContainer).attrs({ Tag: "p" })`
  color: ${colors.white};
  font-size: 1.688em;
  min-height: 7em;
`

const Solution = () => {
  const { qprem, expopartner } = useStaticQuery(graphql`
    query {
      qprem: file(relativePath: { eq: "background/home-00.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, height: 1080)
        }
      }
      expopartner: file(
        relativePath: { eq: "background/case_expopartner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1920, height: 1080)
        }
      }
    }
  `)
  const { t } = useTranslation()
  return (
    <Container>
      <Tabs title={t("Aktuelle Lösungen & erfolgreiche Cases")}>
        <Tab title={t("Qprem")}>
          <BackgroundDark>
            <Text>
              {t(
                "Die globale Softwareplattform zum digitalen Betrieb von Gewerbe- und Industrieimmobilien"
              )}
              .
            </Text>
          </BackgroundDark>
          <ImageContainer {...asBg(qprem)} height="66rem">
            <ParallaxProvider>
              <HeadlineContainer align="left">
                <Parallax y={[-15, 15]}>
                  <Headline colorTheme="black">
                    {t("Qprem")}
                    <br />
                    <span>
                      {t("der digitalisierte")}
                      <br />
                      {t("industriepark")}
                    </span>
                  </Headline>
                </Parallax>
              </HeadlineContainer>
            </ParallaxProvider>
          </ImageContainer>
          <TextBlock align="left" margin="0" colorTheme="primary">
            <div>
              <p>
                {t(
                  "Als Partner von qprem haben wir von Planung und Konzeption über Design bis hin zur Programmierung von Website und App, sowie Backend, Analytics und CMS alles umgesetzt."
                )}
              </p>
              <p>
                {t(
                  "Unter Einhaltung höchster Sicherheitsstandards und dem Einsatz neuster Technologien haben wir mit qprem eine Plattform entwickelt, die Immobilien auf den modernsten Stand der digitalen Möglichkeiten hebt."
                )}
              </p>
              <Link
                color="primary"
                href="https://qprem.de/"
                title=""
                target="_blank"
              >
                {t("Zur Kundenseite")}
              </Link>
            </div>
          </TextBlock>

          <MinSpacing />

          <StickyContentContainer block={true} align="right">
            <StickyContent align="right" width="100%">
              <StaticImage
                src="../images/cases/qprem/uebersicht-qprem.jpg"
                alt=""
              />
            </StickyContent>
          </StickyContentContainer>

          <SmSpacing />

          <StickyContentContainer block={true} align="right">
            <TextSegment
              align="left"
              width="80rem"
              colorTheme="primary"
              headline={t("Smarte Tools zur Systemüberwachung")}
              text={t(
                "Die App dient allen Nutzern als zentrales Informationssystem innerhalb des gesamten Areals. Anhand des skalierbaren Angebots können Services flexibel implementiert, kombiniert und bedarfsgerecht angepasst werden. Mittels des technischen und Modularen Aufbaus kann die qprem App unkompliziert in Infrastrukturen und bestehende Systeme integriert werden. Updates, Anpassungen sowie das Integrieren weiterer Services können jederzeit im laufenden Betrieb vorgenommen werden."
              )}
            />
          </StickyContentContainer>

          <MinSpacing />

          <StickyContentContainer align="left">
            <TextSegment
              align="left"
              width="45rem"
              colorTheme="primary"
              headline={t("Parking & E-Mobility")}
              text={t(
                "Anhand intelligenter Sensoren und Softwarelösungen ermöglicht qprem ein wirtschaftliches Parkraummanagement, wodurch Nutzer:innen Zeitverlust und Stress auf dem Weg zum Stellplatz erspart bleiben."
              )}
              secondText={t(
                "Durch die Verwendung der qprem App kann die Auslastung der Parkplätze überprüft und gesteuert werden, wodurch Stellplätze aufgrund von Mehrfachbelegung optimal genutzt werden können. Vor der Anreise zum Gelände wird die Route für Mitarbeiter:innen und Besucher:innen geplant, sodass jene den für sie optimalen Stellplatz auf dem Areal zugewiesen und nach Befahren des Geländes den Weg dorthin navigiert bekommen."
              )}
            />

            <StickyContent align="left" width="49rem">
              <StaticImage src="../images/cases/qprem/Monitor.jpg" alt="" />
            </StickyContent>
          </StickyContentContainer>

          <Spacing />

          <InfoMenu
            marginBefore="medium"
            marginAfter="medium"
            qprem={true}
            header={t("Vorteile auf einen Blick")}
            items={[
              {
                label: t("Zeitersparnis & Sicherheit"),
                text: t(
                  "Arbeitsstellen bezogene Stellplatzzuordnung sorgt für verkürzte Wege und erspart Zeit – die Sicherheit auf dem Weg zum Arbeitsplatz wird erhöht"
                ),
                anim: animationZeitersparnis,
              },
              {
                label: t("Externe Vermietungen"),
                text: t(
                  "Selten oder ungenutzte Stellplätze werden über das System markiert und können an Externe Firmen vermietet werden."
                ),
                anim: animationExterne,
              },
              {
                label: t("Mehrfachnutzung"),
                text: t(
                  "Mehrfachnutzung der Stellflächen, beispielsweise für Schichtarbeit und Veranstaltungen"
                ),
                anim: animationMehrfach,
              },
              {
                label: t("Abrechnung & Zeiterfassung"),
                text: t(
                  "Abrechnung nur für tatsächlich genutzte Stellflächen anhand der Parkzeit"
                ),
                anim: animationAbrechnung,
              },
              {
                label: t("Ladedock Zuweisung"),
                text: t(
                  "Stellplätze und Lade Tore für LKWs können in Echtzeit zugewiesen und angepasst werden, beispielsweise für verspätete oder ungeplante LKW-Anfahrten"
                ),
                anim: animationLade,
              },
            ]}
            id={""}
          />

          <MinSpacing />

          <StickyContentContainer block={true} align="right">
            <StickyContent align="right" width="100%">
              <StaticImage src="../images/cases/qprem/Maps.jpg" alt="" />
            </StickyContent>
          </StickyContentContainer>

          <SmSpacing />

          <StickyContentContainer block={true} align="right">
            <TextSegment
              align="left"
              width="80rem"
              colorTheme="primary"
              headline={t("Geländekarten & Navigation")}
              text={t(
                "Die visuelle Orientierungshilfe führt Mitarbeiter:innen, Arealbesucher:innen und externe Dienstleister:innen sicher und auf dem schnellsten Weg zum jeweiligen Ziel. Die Routenführung navigiert Nutzer:innen nicht nur an den nächstgelegenen Stellplatz des Ziels, vielmehr werden sie über fußläufige Wege über das Gelände in das Stockwerk des jeweiligen Gebäudes, bis hin zur entsprechenden Eingangstür geführt."
              )}
            />
          </StickyContentContainer>

          <MinSpacing />

          <StickyContentContainer align="right">
            <TextSegment
              align="right"
              width="45rem"
              colorTheme="primary"
              headline={t("Zugangskontrollen")}
              text={t(
                "In der qprem Desktop Applikation werden für alle Mitarbeiter:innen und Besucher:innen Berechtigungen festgelegt, um einen sicheren und nachhaltigen Einlass- und Zugangsprozess zu ermöglichen. Durch die hinterlegten Daten können Dreh- und Schließtüren, Schranken sowie Tore automatisch über das Smartphone geöffnet werden. Besucher:innen haben die Möglichkeit, sich über den schnellsten Weg sicher und kontrolliert zum Besuchsziel führen zu lassen."
              )}
            />

            <StickyContent align="right" width="49rem">
              <StaticImage src="../images/cases/qprem/Access.jpg" alt="" />
            </StickyContent>
          </StickyContentContainer>

          <MinSpacing />

          <StickyContentContainer align="left">
            <TextSegment
              align="left"
              width="45rem"
              colorTheme="primary"
              headline={t("Effizienzsteigerung durch Digitalisierung")}
              text={t(
                "Mit qprem wird Gebäudeverwaltung digital. Machine learning macht qprem zu einem sich selbst optimierenden System, sodass aussagekräftige Erfolgsanalysen induziert werden können. Die globale Softwarelösung ermöglicht anhand der Datengrundlage eine einfache Kontrolle, Steuerung und Auswertung von Prozessabläufen."
              )}
              secondText={t(
                "Anhand der hohen Konnektivität und der digitalen Erfassung von Analysedaten, unterstützt qprem bei der digitalen Transformation von Gebäuden zu Smart Buildings. Das Dashboard lässt sich nach Bedarf frei konfigurieren, sodass je nach Anwendungsgebiet verschiedene Informationen im Fokus stehen. Die gesammelten Daten werden gespeichert und können anhand von Zeitachsen über Jahre abgesteckt und exportiert werden. Dadurch lassen sich relevante Kennzahlen darstellen und Optimierungspotenziale identifizieren. Das Verwalten und Analysieren der Daten ist als kontinuierlicher Prozess wichtiger Bestandteil für das Entwickeln optimierter Lösungen innerhalb von Unternehmen."
              )}
            />

            <StickyContent align="left" width="49rem">
              <StaticImage src="../images/cases/qprem/Analytics.jpg" alt="" />
            </StickyContent>
          </StickyContentContainer>

          <Spacing />

          <StickyContentContainer align="right">
            <TextSegment
              align="right"
              width="45rem"
              colorTheme="primary"
              headline={t("Zugeschnitten auf den individuellen Bedarf")}
              text={t(
                "Die App dient allen Nutzer:innen als zentrales Informationssystem innerhalb des gesamten Areals. Anhand des skalierbaren Angebots können Services flexibel implementiert, kombiniert und bedarfsgerecht angepasst werden. Mittels des technischen und modularen Aufbaus kann die qprem App unkompliziert in Infrastrukturen und bestehende Systeme integriert werden. Updates, Anpassungen sowie das Integrieren weiterer Services können jederzeit im laufenden Betrieb vorgenommen werden."
              )}
            />

            <StickyContent align="right" width="49rem">
              <StaticImage src="../images/cases/qprem/Services.jpg" alt="" />
            </StickyContent>
          </StickyContentContainer>

          <Spacing />

          <BackgroundContainer>
            <StickyContentContainer align="leftTop" className="last-block">
              <TextSegment
                width="45rem"
                align="leftTop"
                headline={t("Maximale Vorteile für Angestellte")}
                text={t(
                  "In einer sich schnell wandelnden und digitalisierten Arbeitsumgebung bietet qprem ein innovatives Leitsystem als ein hilfreiches Tool, das Nutzern durch der damit verbundenen Zeitersparnisse und Orientierungspunkte einen großen Mehrwert bietet."
                )}
                secondText={t(
                  "Anhand der Ortungs,-Trackings- und Navigationsfunktion kann die qprem App als Evakuierungshilfe eingesetzt werden und maximiert die Effizienz im Rahmen des Notfall- und Störfallmanagements."
                )}
              />
              <StickyContent align="leftTop" width="49rem">
                <StaticImage
                  src="../images/cases/qprem/Mobile-App.jpg"
                  alt=""
                />
              </StickyContent>
            </StickyContentContainer>
          </BackgroundContainer>
        </Tab>
        <Tab title={t("Expopartner")}>
          <BackgroundDark>
            <Text></Text>
          </BackgroundDark>
          <ImageContainer
            {...asBg(expopartner)}
            height="66rem"
            id="Expopartner"
          >
            <ParallaxProvider>
              <HeadlineContainer align="left">
                <Parallax y={[-15, 15]}>
                  <Headline colorTheme="white">
                    {t("Expopartner")}
                    <br />
                    <span>
                      {t("hub-i: der digital")}
                      <br />
                      {t("vernetzte Messestand")}
                    </span>
                  </Headline>
                </Parallax>
              </HeadlineContainer>
            </ParallaxProvider>
          </ImageContainer>
          <TextBlock align="left" margin="0" colorTheme="primary">
            <div>
              <p>
                {t(
                  "HUB-i ist ein digitales Event-System mit Tracking- und Analyse-Funktion – anonym und DSGVO konform. Ein speziell für die Live-Kommunikation entwickeltes System, das analoge Meeting Points und das digitale Nutzererlebnis mess- und greifbar macht."
                )}
              </p>
              <p>
                {t(
                  "Hier verbindet HUB-I innovative Softwarelösungen miteinander und ermöglicht es dadurch, Besuchern in jeder Phase an der Messe teilzunehmen – ob in der Vorfeldkommunikation bis zur Nachbereitung, physisch am Messestand oder online via Smartphone oder Web."
                )}
              </p>
              <Link
                color="primary"
                href="https://www.expopartner.de/leistungen/innovation-und-loesungen#c86"
                title=""
                target="_blank"
              >
                {t("Zur Kundenseite")}
              </Link>
            </div>
          </TextBlock>

          <SmSpacing />

          <StickyContentContainer block={true} align="right">
            <StickyContent align="right" width="100%">
              <Vimeo video="461841214" width={1280} height={732} />
            </StickyContent>
          </StickyContentContainer>

          <SmSpacing />

          <StickyContentContainer block={true} align="right">
            <TextSegment
              align="left"
              width="80rem"
              colorTheme="primary"
              headline={t("Modularer Ansatz")}
              text={t(
                "Das System besteht aus einem komplexen Backend und mehreren Modulen, die je nach Bedarf eingesetzt werden können."
              )}
              secondText={t(
                "Alle Module sind mit dem HUB-i verbunden. Der Datenabgleich funktioniert in beide Richtungen in Echtzeit (two-way realtime communication), so dass Tracking-Daten von jedem Modul in das Backend einfließen und Inhalte, die im Backend eingepflegt werden, auf die einzelnen Module ausgespielt werden."
              )}
              thirdText={t(
                "Eine Schnittstellenprogrammierung zu Fremd-CRM Systemen des Kunden ist möglich."
              )}
            />
          </StickyContentContainer>
        </Tab>
      </Tabs>
    </Container>
  )
}

export default Solution
