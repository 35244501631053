import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { fonts, colors, layout, bp } from "../components/Styles"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "swiper/css"
import "swiper/css/pagination"

const Customers = () => {
  const isMobile = useBreakpoint().widthMax710
  const { t } = useTranslation()

  return (
    <Container>
      <Headline>{t("Eine Auswahl unserer Kunden")}</Headline>

      {isMobile ? (
        <Swiper
          loop={true}
          centeredSlides={false}
          spaceBetween={0}
          slidesPerView={3}
          grabCursor={true}
          pagination={false}
          className="mySwiper"
        >
          <SwiperSlide>
            <StaticImage src="../images/customers/ZEISS.svg" alt="ZEISS" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../images/customers/Lufthansa.svg"
              alt="Lufthansa"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Messe_Frankfurt.svg"
              alt="Messe_Frankfurt"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage src="../images/customers/Sanofi.svg" alt="Sanofi" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Telefonica.svg"
              alt="Telefonica"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/UKH_Unfallkasse_Hessen.svg"
              alt="UKH_Unfallkasse_Hessen"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Fraunhofer.svg"
              alt="Fraunhofer"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Thyssenkrupp.svg"
              alt="Thyssenkrupp"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage src="../images/customers/Opel.svg" alt="Opel" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage src="../images/customers/Blukii.svg" alt="Blukii" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage src="../images/customers/Pfizer.svg" alt="Pfizer" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Miles_More.svg"
              alt="Miles_More"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Heimtextil.svg"
              alt="Heimtextil"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Infraserv.svg"
              alt="Infraserv"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/Europa_Park.svg"
              alt="Europa_Park"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../images/customers/EllenWille.svg"
              alt="EllenWille"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage src="../images/customers/Uniflex.svg" alt="Uniflex" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage src="../images/customers/eon.svg" alt="eon" />
          </SwiperSlide>
        </Swiper>
      ) : (
        <ImagesContainer>
          <StaticImage src="../images/customers/ZEISS.svg" alt="ZEISS" />
          <StaticImage
            src="../images/customers/Lufthansa.svg"
            alt="Lufthansa"
          />
          <StaticImage
            src="../images/customers/Messe_Frankfurt.svg"
            alt="Messe_Frankfurt"
          />
          <StaticImage src="../images/customers/Sanofi.svg" alt="Sanofi" />
          <StaticImage
            src="../images/customers/Telefonica.svg"
            alt="Telefonica"
          />
          <StaticImage
            src="../images/customers/UKH_Unfallkasse_Hessen.svg"
            alt="UKH_Unfallkasse_Hessen"
          />
          <StaticImage
            src="../images/customers/Fraunhofer.svg"
            alt="Fraunhofer"
          />
          <StaticImage
            src="../images/customers/Thyssenkrupp.svg"
            alt="Thyssenkrupp"
          />
          <StaticImage src="../images/customers/Opel.svg" alt="Opel" />
          <StaticImage src="../images/customers/Blukii.svg" alt="Blukii" />
          <StaticImage src="../images/customers/Pfizer.svg" alt="Pfizer" />
          <StaticImage
            src="../images/customers/Miles_More.svg"
            alt="Miles_More"
          />
          <StaticImage
            src="../images/customers/Heimtextil.svg"
            alt="Heimtextil"
          />
          <StaticImage
            src="../images/customers/Infraserv.svg"
            alt="Infraserv"
          />
          <StaticImage
            src="../images/customers/Europa_Park.svg"
            alt="Europa_Park"
          />
          <StaticImage
            src="../images/customers/EllenWille.svg"
            alt="EllenWille"
          />
          <StaticImage src="../images/customers/Uniflex.svg" alt="Uniflex" />
          <StaticImage src="../images/customers/eon.svg" alt="eon" />
        </ImagesContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding-top: 4em;
  padding-bottom: 4em;
  background: ${colors.backgroundDarker};
  width: 100%;
  ${bp("mobile")`
  padding-bottom:0;
  padding-top: 0;
`}
`

const ImagesContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  > * {
    max-width: 189px;
  }
  width: ${layout.width};
  max-width: ${layout.maxWidth};
  margin: 0 auto;
  ${bp("mobile")`
    max-width: ${layout.maxWidthMobile};
  `}
`
const Headline = styled.h3`
  color: ${colors.primary};
  font-family: ${fonts.medium};
  font-size: 2em;
  padding: 2em 0;
  width: ${layout.width};
  max-width: ${layout.maxWidth};
  margin: 0 auto;
  ${bp("mobile")`
    max-width: ${layout.maxWidthMobile};
    font-size: 1.8em;
  `}
`

export default Customers
