import React from "react"
import {
  Container,
  HeadlineContainer,
  HeroHeader,
  TextBlock,
} from "../components/layout-components"
import { graphql } from "gatsby"
import { asBg } from "../util/image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Customers from "../components/Customers"
import Solution from "../components/Solution"

const Cases: React.FC<{ data: any }> = ({ data }) => {
  const { headerBackground } = data

  const { t } = useTranslation()

  return (
    <Container>
      <Helmet>
        <title>Garage51 | {t("Cases")}</title>
      </Helmet>

      <HeroHeader {...asBg(headerBackground)}>
        <HeadlineContainer align="left">
          <h1>
            {t("Erfolgreiche")}
            <span className="subHeadline"> {t("Cases")}</span>
            <br />
            <span className="subHeadline">{t("und")} </span>
            {t("zufriedene")}
            <br />
            {t("Kunden")}
          </h1>
        </HeadlineContainer>
      </HeroHeader>

      <TextBlock align="left" margin="0">
        <div>
          <p>
            {t(
              "Bei allen Projekten, die wir übernehmen, behandeln wir Informationen zum Kunden und zum Projekt vertraulich und vereinbaren dazu auch gerne schriftliche Vertraulichkeits-Regelungen. Einige der von uns umgesetzten Projekte erscheinen daher auch nicht auf unserer Website. Wenn Sie nach bestimmten Cases suchen oder Beispiele für Umsetzungen benötigen, sprechen Sie uns gerne an, wir stellen Ihnen ein individuelles Paket zusammen."
            )}
          </p>
        </div>
      </TextBlock>

      <Customers />

      <TextBlock align="left" margin="0">
        <div>
          <h2>{t("Von kleinen Anwendungen bis zu großen Plattformen")}</h2>
          <p>
            {t(
              "Je nach Aufgabenstellung und Bedarf entwickeln wir eigene Datenbanken, erweitern bestehende Systeme, konzipieren Analyse-Tools oder programmieren Apps. Digital kann den Arbeitsalltag erleichtern und verbessern oder neue Geschäftsbereiche ermöglichen. Dabei muss es nicht gleich ein komplexes System sein, ein wichtiges und täglich genutztes Formular, das aufwendig in Excel erstellt, ausgedruckt und verteilt wird, ist als Tablet App deutlich einfacher und effizienter."
            )}
          </p>
          <p>
            {t(
              "Die Frage, ob digital oder nicht stellt sich in vielen Fällen nicht mehr. Die Entscheidung jedoch in Bezug auf Form, Medium und Umfang ist umso wichtiger. Wir beraten Sie gerne zu individuellen, digitalen Lösungen für Ihr Unternehmen."
            )}
          </p>
        </div>
      </TextBlock>

      <Solution />

      <Footer />
    </Container>
  )
}

export default Cases

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "cases"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    headerBackground: file(
      relativePath: { eq: "background/Expertise_Header.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }
  }
`
