import React from "react"
import styled from "styled-components"
import { fonts, colors } from "../Styles"

type Props = {
  title: string
  index: number
  selectedTab: number
  urlTab?: number
  setSelectedTab: (index: number) => void
}

const Tab = styled.li`
  flex: 50%;
  > h4 {
    font-size: 1.4em;
    color: ${colors.white};
    font-family: ${fonts.bold};
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    padding-bottom: 1.5em;
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      background-color: ${colors.primary};
      height: 0.5em;
      transition: all ease-in-out 0.2s;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &.active,
    &:hover {
      &:after {
        height: 1em;
      }
    }
  }
`

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  selectedTab,
  urlTab,
  index,
}) => {
  if (urlTab) {
    setSelectedTab(urlTab)
  }

  return (
    <Tab>
      <h4
        className={index === selectedTab ? "active" : ""}
        onClick={() => setSelectedTab(index)}
        id={title}
      >
        {title}
      </h4>
    </Tab>
  )
}

export default TabTitle
